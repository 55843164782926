import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import { useUsers, useAlerts } from "common";
import { isValidUser } from "app/shared/utils";
import { GET_OUTREACH_CONFIGURATION_CONFIGS } from "app/shared/constants";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const { currentUser } = useUsers();
  const { setAlert } = useAlerts();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (isValidUser(currentUser)) {
      (async () => {
        try {
          const response = await axios.get(GET_OUTREACH_CONFIGURATION_CONFIGS);
          setPermissions(response.data.configs);
        } catch (err) {
          setAlert("error", err.message);
        }
      })();
    }
  }, [currentUser, setAlert]);

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};
