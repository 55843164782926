import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar as MuiAvatar,
  Button as MuiButton,
  Checkbox as MuiCheckbox,
  CircularProgress as MuiCircularProgress,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  FormGroup as MuiFormGroup,
  Grid as MuiGrid,
  IconButton as MuiIconButton,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  TextField as MuiTextField,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
} from "@material-ui/core";
import {
  AccessTime as MuiAccessTimeIcon,
  Autorenew as MuiAutorenewIcon,
  Cancel as MuiCancelIcon,
  ErrorOutline as MuiErrorOutlineIcon,
  Info as MuiInfoIcon,
  Star as MuiStarIcon,
  Warning as MuiWarningIcon,
} from "@material-ui/icons";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
  KeyboardTimePicker as MuiKeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useAlerts } from "common";
import { addMonths, addYears, format } from "date-fns";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Autocomplete } from "app/UI/Autocomplete/Autocomplete";
import { useStyles } from "app/outreach/ConductOutreachPageStyles";
import { usePermissions } from "app/services/PermissionsService";
import { getOutreachErrorSummary } from "app/services/outreachServices";
import {
  DaysOfTheWeek,
  DefaultRecurringParam,
  DemographicOptimization,
  GET_STUDIES_URL_BASE,
  OutreachChannelModes,
  OutreachHistoryDefaults,
  OutreachInitiationModes,
  OutreachScheduleModes,
  OutreachStatuses,
  StudyChannelOptions,
  SummaryAccrual,
  URLs,
} from "app/shared/constants";
import {
  addIndexToElements,
  convertDateStringToLocalDatetime,
  getDisplayPercentage,
  mapOutreachStatsToDisplayed,
  mapStudyToDisplayed,
} from "app/shared/utils";
import OutreachDetails from "app/outreach/OutreachDetails";
import OutreachFailureSummary from "app/shared/UI/OutreachFailureSummary";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import PEErrorDialog from "app/shared/UI/PEErrorDialog";
import PEExport from "app/shared/UI/PEExport";

const errorSummaryContent = {
  primaryContent: "identifier",
  secondaryContent: "message",
};

const firstOutreachMinDate = new Date(Date.now() + 86400000);

const ConductOutreachPageContent = (props) => {
  const { studyId } = useParams();
  const classes = useStyles();
  const [study, setStudy] = useState(null);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [refresh, setRefresh] = useState(false);
  const [checkAvailabilityLoader, setCheckAvailabilityLoader] = useState(false);
  const [conductOutreachLoader, setConductOutreachLoader] = useState(false);
  const history = useHistory();
  const { permissions } = usePermissions();

  const [cohortSources, setCohortSources] = useState([]);
  const [prospectCounts, setProspectCounts] = useState([]);
  const [recurringOutreachDate, setRecurringOutreachDate] = useState(
    () => new Date()
  );

  const [outreachHistory, setOutreachHistory] = useState([]);
  const [outreachStatistics, setOutreachStatistics] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOutreach, setSelectedOutreach] = useState(-1);
  const [failureSummaryDialog, setFailureSummaryDialog] = useState(false);
  const [failedOutreach, setFailedOutreach] = useState(-1);
  const [openASConfirmation, setOpenASConfirmation] = useState(false);
  const [
    openASConfirmationForCancelRecurringWave,
    setOpenASConfirmationForCancelRecurringWave,
  ] = useState(false);
  const [
    openASConfirmationForDisabledChannels,
    setOpenASConfirmationForDisabledChannels,
  ] = useState(false);
  const [isSelectedChannelDisable, setIsSelectedChannelDisable] =
    useState(false);
  const [createOutreachChannelMode, setCreateOutreachChannelMode] = useState(
    OutreachChannelModes.DEFAULT
  );
  const [cancelOutreachRow, setCancelOutreachRow] = useState({});
  const [cancelRecurringOutreachRow, setCancelRecurringOutreachRow] = useState(
    {}
  );

  const [
    overrideExistingRecurringSchedule,
    setOverrideExistingRecurringSchedule,
  ] = useState(false);

  const [
    openASConfirmationForExistingRecurringSchedule,
    setOpenASConfirmationForExistingRecurringSchedule,
  ] = useState(false);

  const [recurringOutreach, setRecurringOutreach] = useState([]);

  const [outreachHistoryFilters, setOutreachHistoryFilters] = useState({
    sort: `${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY},${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDER}`,
    page: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGE,
    size: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGESIZE,
  });
  const [sort, setSort] = useState({
    order: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDER,
    orderBy: OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY,
  });
  const [page, setPage] = useState(
    outreachHistoryFilters.page ||
      OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGE
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    outreachHistoryFilters.size ||
      OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTPAGESIZE
  );
  const [outreachHistoryTotalCount, setOutreachHistoryTotalCount] = useState(0);
  const [errorsList, setErrorList] = useState([]);
  const [
    openOutreachFailureSummaryDialog,
    setOpenOutreachFailureSummaryDialog,
  ] = useState(false);

  const newErrorRecord = (type, identifier, message) => {
    return {
      type: type,
      identifier: identifier,
      message: message,
    };
  };

  const handleOutreachErrorContent = (outreach) => {
    getOutreachErrorSummary(
      outreach,
      setErrorList,
      setAlert,
      newErrorRecord,
      t
    );
  };

  const getFirstOutreachDate = (channelName) => {
    if (channelName === StudyChannelOptions.MH) {
      return format(new Date(firstOutreachMinDate), "MM/dd/yyyy");
    }
    return format(new Date(), "MM/dd/yyyy");
  };

  const getOutreachDateAndTime = (channelName, channelEnable) => {
    if (channelName === StudyChannelOptions.MH && channelEnable) {
      return format(
        new Date(firstOutreachMinDate).setHours(0, 0, 0, 0),
        "MM/dd/yyyy hh:mm a"
      );
    }
    return format(new Date(), "MM/dd/yyyy hh:mm a");
  };

  const [formDataResponse] = useState({
    waveName: "",
    source: "",
    channel: "",
    outreachQty: "",
    outreachSchedule: null,
    outreachTime: format(new Date(), "MM/dd/yyyy hh:mm a"),
    firstOutreachDate: format(new Date(), "MM/dd/yyyy"),
    lastOutreachDate: format(addMonths(new Date(), 3), "MM/dd/yyyy"),
    outreachTimeOfDay: format(new Date(), "hh:mm a"),
    waveNote: "",
    scheduleRecurringCheckbox: [],
    templateName: "",
  });

  const formValidationScheme = Yup.object({
    waveName: Yup.string().trim().required(),
    templateName: Yup.lazy((values, parentOptions) => {
      return Yup.object().shape({
        templateId: Yup.number().when({
          is: () => {
            return parentOptions.context.channel
              ? parentOptions.context.channel?.type === "DE" &&
                  permissions.MARKETO_ENABLED
              : false;
          },
          then: () => Yup.number().required().positive().integer(),
          otherwise: () => Yup.number().notRequired(),
        }),
      });
    }),
    source: Yup.object()
      .shape({
        id: Yup.number().required().positive().integer(),
      })
      .nullable(),
    channel: Yup.object().shape({
      type: Yup.string().trim().required(),
    }),
    outreachQty: Yup.number()
      .integer()
      .min(1, t("ConductOutreachPage.outreachQuantityErrorMessage"))
      .required(),
    outreachSchedule: Yup.string().required(),
    outreachTime: Yup.mixed().when("outreachSchedule", {
      is: "ONE_TIME_ONLY",
      then: () => Yup.mixed().required(),
      otherwise: undefined,
    }),
    firstOutreachDate: Yup.mixed().when("outreachSchedule", {
      is: "RECURRING",
      then: () => Yup.mixed().required(),
      otherwise: undefined,
    }),
    lastOutreachDate: Yup.mixed().when("outreachSchedule", {
      is: "RECURRING",
      then: () => Yup.mixed().required(),
      otherwise: undefined,
    }),
    outreachTimeOfDay: Yup.mixed().when("outreachSchedule", {
      is: "RECURRING",
      then: () =>
        Yup.mixed()
          .required()
          .when("channel", {
            is: (channel) => channel !== StudyChannelOptions.MH,
            then: () => Yup.mixed().required(),
            otherwise: undefined,
          }),
      otherwise: undefined,
    }),
    scheduleRecurringCheckbox: Yup.mixed().when("outreachSchedule", {
      is: "RECURRING",
      then: () => Yup.array().min(1),
      otherwise: undefined,
    }),
  });

  useEffect(() => {
    (async () => {
      if (studyId) {
        // fetch study by id
        try {
          const response = await axios.get(
            `${GET_STUDIES_URL_BASE}/${studyId}?vw=brief`
          );
          setStudy(mapStudyToDisplayed(response.data)); // save this for comparison at edit save
          if (permissions.MARKETO_ENABLED && !response.data.studyTemplates) {
            setAlert("warning", t("ConductOutreachPage.warningMsg"));
          }
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAlert, refresh]);

  useEffect(() => {
    (async () => {
      if (study) {
        try {
          // fetch outreachable imports by protocolNumber and source
          const outreachRes = await axios.get(
            `${URLs.GET_OUTREACHABLE_URL_BASE}?protocolNumber=${study.protocolNumber}`
          );
          setCohortSources(outreachRes.data); // for cohort source dropdown

          // creating new filters based on single sort and multi-sort
          let filters = {};
          if (Array.isArray(outreachHistoryFilters.sort)) {
            // used URLSearchParams to serialize params and send same param key twice with different values
            const params = new URLSearchParams();
            params.append("sort", outreachHistoryFilters.sort[0]);
            params.append("sort", outreachHistoryFilters.sort[1]);
            params.append("page", outreachHistoryFilters.page);
            params.append("size", outreachHistoryFilters.size);

            filters = params;
          } else {
            filters = outreachHistoryFilters;
          }

          const historyResponse = await axios.get(
            `${URLs.GET_OUTREACH_HISTORY_URL_BASE}/${study.id}`,
            { params: filters }
          );
          setOutreachHistory(historyResponse.data.values); // for cohort source dropdown
          setOutreachHistoryTotalCount(historyResponse.data.totalCount);

          const recurringResponse = await axios.get(
            `${URLs.GET_OUTREACH_HISTORY_URL_BASE}/${study.id}${URLs.GET_OUTREACH_RECURRING_SCHEDULE_URL_BASE}`,
            { params: DefaultRecurringParam }
          );
          setRecurringOutreach(recurringResponse.data); // for cohort source dropdown
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
  }, [study, setAlert, outreachHistoryFilters]);

  useEffect(() => {
    (async () => {
      // We want to refresh outreach statistics when outreach history changes
      // and we only care about refreshing the stats if there's some outreach
      // history already
      if (study && outreachHistory && outreachHistory.length > 0) {
        try {
          const response = await axios.get(
            `${URLs.GET_OUTREACH_HISTORY_URL_BASE}/${study.id}${URLs.GET_OUTREACH_STATISTICS_URL_BASE}`
          );
          setOutreachStatistics(mapOutreachStatsToDisplayed(response.data)); // for Outreach Statistics
          // tally the total count instead of refreshing the study object from backend
          // this is to elimiate the infinite loop of rendering from study->outreachHistory->study
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
  }, [outreachHistory, setAlert, study]);

  const handleClickCheckAvailability = (values, setFieldValue) => {
    setCheckAvailabilityLoader(true);
    // check if a cohort source is selected
    if (!values.source.id) {
      // no cohort source selected
      setCheckAvailabilityLoader(false);
      setAlert("error", t(`ConductOutreachPage.checkAvailabilityAlert`));
    } else {
      // calling BE API /prospects/available-counts?importId=X
      // note: depends on the size of the cohort, this call may take longer
      // than the default 5 seconds so specifying an extended timeout here
      (async () => {
        try {
          const response = await axios.get(
            `${URLs.GET_OUTREACH_PROSPECT_COUNTS_URL_BASE}?importId=${values.source.id}`,
            {
              timeout: process.env.REACT_APP_OUTREACH_REQUEST_TIMEOUT,
            }
          );
          setProspectCounts(response.data); // prospect counts for all channels

          // This is to set outreach quantity when channel is already selected before checking availabity
          values.channel?.type &&
            setFieldValue(
              "outreachQty",
              getProspectCount(
                response.data,
                values.channel.type,
                "presetQuantity"
              )
            );
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        } finally {
          setCheckAvailabilityLoader(false);
        }
      })();
    }
  };

  const handleChangeChannel = async (setFieldValue, channelName) => {
    await setFieldValue("channel", channelName);
    setFieldValue(
      "outreachQty",
      getProspectCount(prospectCounts, channelName.type, "presetQuantity")
    );
    setCreateOutreachChannelMode(OutreachChannelModes.DEFAULT);
    setIsSelectedChannelDisable(false);
    setFieldValue("outreachSchedule", "");
  };

  const handleClickSubmit = (values, setSubmitting, resetForm) => {
    setConductOutreachLoader(true);
    // first check if outreach qty is <= available count
    if (
      values.outreachQty >
      getProspectCount(prospectCounts, values.channel.type, "available")
    ) {
      // alert message and return
      setAlert(
        "error",
        t(`ConductOutreachPage.validationErrorOutreachQtyGreaterThanAvailable`)
      );
      setSubmitting(false);
      setConductOutreachLoader(false);
      return;
    }
    handleCreateOutreach(
      values,
      overrideExistingRecurringSchedule,
      setSubmitting,
      resetForm
    );
  };

  const handleCreateOutreach = (
    values,
    existingRecurringSchedule,
    setSubmitting,
    resetForm
  ) => {
    let scheduleSummary = {};
    if (values.outreachSchedule === "RECURRING") {
      scheduleSummary = {
        endDate: format(new Date(values.lastOutreachDate), "yyyy-MM-dd"),
        overrideExistingSchedule: existingRecurringSchedule,
        recurringDays: values.scheduleRecurringCheckbox,
        recurringTime:
          values.channel.type !== StudyChannelOptions.MH
            ? format(new Date(recurringOutreachDate), "HH:mm:ss")
            : format(new Date().setHours(0, 0, 0, 0), "HH:mm:ss"),
        startDate: format(new Date(values.firstOutreachDate), "yyyy-MM-dd"),
        status: "ACTIVE",
      };
    }

    let newData = {
      waveName: values.waveName,
      importRequest: { id: values.source.id },
      channel: values.channel.type,
      emailTemplateId:
        values.channel.type === "DE" ? values.templateName.templateId : null,
      quantity: values.outreachQty,
      conductedDate:
        (values.outreachSchedule === "ONE_TIME_ONLY" &&
          new Date(values.outreachTime).toISOString()) ||
        null,
      note: values.waveNote,
      scheduleType: values.outreachSchedule,
      scheduleSummary: scheduleSummary,
    };

    Object.keys(newData).forEach((key) => {
      if (newData[key] === null) {
        delete newData[key];
      }
    });

    createOutreach(values, newData, setSubmitting, resetForm);
  };

  const createOutreach = async (values, newData, setSubmitting, resetForm) => {
    try {
      // note: depends on the size of the cohort, this call may take longer
      // than the default 5 seconds so specifying an extended timeout here
      await axios.post(
        `${URLs.GET_OUTREACH_URL_BASE}?mode=${createOutreachChannelMode}`,
        newData,
        {
          timeout: process.env.REACT_APP_OUTREACH_REQUEST_TIMEOUT,
        }
      );

      const location = {
        state: null,
      };
      history.replace(location);
      setRefresh((refresh) => !refresh);
      // reset the outreach fields
      handleClickReset(resetForm);
    } catch (err) {
      if (err.code === "ECONNABORTED") {
        setAlert("error", t("ConductOutreachPage.timeOutErrorMessage"));
      } else if (err.response && err.response.data) {
        if (
          err.response.data.status === 400 &&
          err.response.data.code === "INVALID_ACTION" &&
          err.response.data.detail.properties[0].name === "Conduct Outreach"
        ) {
          // set error.message in AlertContext
          setAlert(
            "error",
            t("ConductOutreachPage.errorMessageForDisabledChannels", {
              channelName: `"${t(
                "recruitmentPreference." + values.channel.type
              )}"`,
            })
          );
        } else if (
          err.response.data.status === 400 &&
          err.response.data.code === "INVALID_CHANNEL"
        ) {
          // set error.message in AlertContext
          setAlert(
            "error",
            t("ConductOutreachPage.errorMessageForInvalidChannels", {
              channelName: `"${t(
                "recruitmentPreference." + values.channel.type
              )}"`,
            })
          );
        } else if (
          err.response.data.status === 400 &&
          err.response.data.code === "INVALID_ACTION" &&
          err.response.data.detail.properties[0].name === "Outreach Schedule"
        ) {
          // set error.message in AlertContext
          setOpenASConfirmationForExistingRecurringSchedule(true);
        } else if (
          err.response.data.status === 400 &&
          err.response.data.code === "INVALID_VALUE" &&
          err.response.data.detail.properties[0].name === "waveName"
        ) {
          // set error.message in AlertContext
          setAlert(
            "error",
            t("ConductOutreachPage.errorMessageForUniqueWaveName")
          );
        } else {
          setAlert("error", err.message);
        }
      } else {
        // set error.message in AlertContext
        setAlert("error", err.message);
      }
      setSubmitting(false);
    } finally {
      setConductOutreachLoader(false);
    }
  };

  const handleClickReset = (resetForm) => {
    resetForm({ values: formDataResponse });
    setProspectCounts([]);
    setOverrideExistingRecurringSchedule(false);
    setIsSelectedChannelDisable(false);
  };

  const getRecruited = (data) => {
    if (data.protocolTargetAccrual > 0 && data.subjectSummary) {
      const cnt = getDisplayPercentage(
        (data.subjectSummary.enrolled * 100) / data.protocolTargetAccrual
      );
      return (
        cnt +
        "% (" +
        data.prospectOnStudy +
        "/" +
        data.protocolTargetAccrual +
        ")"
      );
    } else {
      return "";
    }
  };

  const getStatsDisplay = (data) => {
    if (data == null || data.value == null || data.percentage == null)
      return t(`NotApplicable`);

    return data.value + " (" + data.percentage + "%)";
  };

  const getCohortStatsDisplay = (data) => {
    if (data == null || data.value == null || data.percentage == null)
      return t(`NotApplicable`);

    return (
      getCohortSourceFileName(parseInt(data.value)) +
      " (" +
      data.percentage +
      "%)"
    );
  };

  const getCohortSourceFileName = (id) => {
    if (cohortSources.length === 0) return "";

    const found = cohortSources.find((item) => item.id === id);
    if (found) return found.filename;

    return "";
  };

  const getProspectCount = (count, channelName, countType) => {
    if (count.length === 0) return "";

    const found = count.find((item) => item.type === channelName);
    if (found) return found[countType];

    return "NA";
  };

  const displayOutreachQuantityDialog = (index) => {
    setOpenDialog(true);
    setSelectedOutreach(index);
  };

  const closeOutreachQuantityDialog = () => {
    setOpenDialog(false);
    setSelectedOutreach(-1);
  };

  const displayFailureSummaryDialog = (index) => {
    setFailureSummaryDialog(true);
    setFailedOutreach(index);
  };

  const closeFailureSummaryDialog = () => {
    setFailureSummaryDialog(false);
    setFailedOutreach(-1);
  };

  const handleClickCancel = () => {
    setOpenASConfirmation(true);
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    // API call to cancel scheduled outreach will go here.
    if (cancelChoice) {
      (async () => {
        try {
          await axios({
            url: `${URLs.GET_OUTREACH_URL_BASE}/${cancelOutreachRow.id}${URLs.GET_OUTREACH_CANCEL_URL_BASE}`,
            method: "PUT",
          });

          setCancelOutreachRow({});
          const location = {
            state: null,
          };
          history.replace(location);
          setRefresh((refresh) => !refresh);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      })();
    }
  };

  const handleClickCancelRecurring = () => {
    setOpenASConfirmationForCancelRecurringWave(true);
  };
  const closeCancelASConfirmationForCancelRecurringWave = (cancelChoice) => {
    setOpenASConfirmationForCancelRecurringWave(false);
    // API call to cancel scheduled outreach will go here.
    if (cancelChoice) {
      (async () => {
        try {
          await axios({
            url: `${URLs.GET_OUTREACH_URL_BASE}${URLs.GET_OUTREACH_RECURRING_SCHEDULE_URL_BASE}/${cancelRecurringOutreachRow.id}${URLs.GET_OUTREACH_CANCEL_URL_BASE}`,
            method: "PUT",
          });

          setCancelRecurringOutreachRow({});
          const location = {
            state: null,
          };
          history.replace(location);
          setRefresh((refresh) => !refresh);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      })();
    }
  };

  const closeCancelASConfirmationForDisabledChannels = (cancelChoice) => {
    setOpenASConfirmationForDisabledChannels(false);
    if (cancelChoice) {
      setCreateOutreachChannelMode(OutreachChannelModes.STANDARD);
    } else {
      setCreateOutreachChannelMode(OutreachChannelModes.AUTOMATED);
    }
  };

  const closeCancelASConfirmationForExistingRecurring = (
    proceedChoice,
    resetForm,
    values
  ) => {
    setOpenASConfirmationForExistingRecurringSchedule(false);
    if (proceedChoice) {
      setConductOutreachLoader(true);
      setOverrideExistingRecurringSchedule(true);
      handleCreateOutreach(values, true);
    } else {
      setOverrideExistingRecurringSchedule(false);
      handleClickReset(resetForm);
    }
  };

  const timeConvert = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };

  const getDaysOfWeekString = (arraySet) =>
    arraySet.map((days, idx) => (idx ? ", " : "") + days.substring(0, 3));

  const sortedArrayOfDaysOfWeek = (data) => {
    let sortedData = [];
    sortedData = data.sort((a, b) => {
      return DaysOfTheWeek.indexOf(a) - DaysOfTheWeek.indexOf(b);
    });

    return getDaysOfWeekString(sortedData);
  };

  const outreachHistoryHeadCells = [
    { id: "waveNumber", label: "tableColumnWaveNumber", align: "center" },
    { id: "outreachMode", label: "tableColumnMode", sort: true },
    { id: "initiationMode", label: "tableColumnRecurringSchedule", sort: true },
    { id: "waveName", label: "tableColumnWaveName", sort: true },
    { id: "status", label: "tableColumnWaveStatus", sort: true },
    { id: "channel", label: "tableColumnChannel", sort: true },
    { id: "cohortSource", label: "tableColumnCohortSource", sort: true },
    { id: "conductedDate", label: "tableColumnTime", sort: true },
    { id: "conductedDay", label: "tableColumnDay", sort: true },
    {
      id: "adjustedOutreachQuantity",
      label: "tableColumnCount",
      sort: true,
      align: "right",
    },
    {
      id: "responded",
      label: "tableColumnResponded",
      sort: true,
      align: "right",
    },
    {
      id: "interested",
      label: "tableColumnInterested",
      sort: true,
      align: "right",
    },
    {
      id: "notInterested",
      label: "tableColumnNotInterested",
      sort: true,
      align: "right",
    },
    {
      id: "unsubscribed",
      label: "tableColumnUnsubscribed",
      sort: true,
      align: "right",
    },
    {
      id: "pepOnStudy",
      label: "tableColumnPepOnStudy",
      sort: true,
      align: "right",
    },
    { id: "note", label: "tableColumnNote", sort: true },
    { id: "action", label: "tableColumnActions" },
  ];

  const createSortHandler = (property) => (event) =>
    handleRequestSort(event, property);

  const handleRequestSort = (event, property) => {
    const isAsc =
      sort.orderBy === property && sort.order === "asc" ? "desc" : "asc";
    setSort({
      orderBy: property,
      order: isAsc,
    });

    //removing sort values from filters before re-assigning to avoid data type conflicts
    let filters = {};
    filters = Object.keys(outreachHistoryFilters)
      .filter((key) => key !== "sort")
      .reduce((obj, key) => {
        obj[key] = outreachHistoryFilters[key];
        return obj;
      }, {});

    //check whether to do single sort or multi-sort
    if (property === OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY) {
      setOutreachHistoryFilters({
        ...filters,
        sort: `${property},${isAsc}`,
      });
    } else {
      setOutreachHistoryFilters({
        ...filters,
        sort: [
          `${property},${isAsc}`,
          `${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDERBY},${OutreachHistoryDefaults.OUTREACHHISTORYDEFAULTORDER}`,
        ],
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setOutreachHistoryFilters({
      ...outreachHistoryFilters,
      page: 0,
      size: parseInt(event.target.value, 10),
    });
  };

  const closeOutreachFailureSummary = () => {
    setOpenOutreachFailureSummaryDialog(false);
  };

  const setTemplateNameValue = (templates, setFieldTouched, setFieldValue) => {
    if (templates.length === 0) {
      setFieldTouched("templateName", true);
    } else if (study.studyTemplates.length === 1) {
      setFieldValue("templateName", study.studyTemplates[0]);
    } else {
      setFieldValue(
        "templateName",
        study.studyTemplates.find((template) => template.isDefault === true)
      );
    }
  };

  return study ? (
    <div className={classes.root}>
      {openOutreachFailureSummaryDialog && (
        <OutreachFailureSummary
          onClose={closeOutreachFailureSummary}
          open={openOutreachFailureSummaryDialog}
          isDisplayConductOutreachButton={false}
          studyId={study.id}
          outreachRefresh={refresh}
          setOutreachRefresh={setRefresh}
          history={history}
        />
      )}
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={
          cancelOutreachRow.initiationMode === "USER_INITIATED"
            ? t("ConductOutreachPage.confirmationMessageCancel", {
                waveName: cancelOutreachRow && cancelOutreachRow.waveName,
              })
            : t("ConductOutreachPage.confirmationMessageCancelRecurring", {
                waveName: cancelOutreachRow && cancelOutreachRow.waveName,
              })
        }
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <Formik
        enableReinitialize
        initialValues={formDataResponse}
        validationSchema={formValidationScheme}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleClickSubmit(values, setSubmitting, resetForm);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          isSubmitting,
          handleChange,
          setFieldValue,
          touched,
          handleBlur,
          resetForm,
          validateField,
          setFieldTouched,
        }) => {
          const valueForOutReach = (outreachValue) => {
            if (outreachValue === "ONE_TIME_ONLY") {
              return "Schedule One Time Only";
            } else if (outreachValue === "RECURRING") {
              return "Schedule Recurring";
            }
          };
          return (
            <Form>
              <ConfirmationDialog
                open={openASConfirmationForDisabledChannels}
                onClose={closeCancelASConfirmationForDisabledChannels}
                message={t(
                  "ConductOutreachPage.confirmationMessageForDisabledChannel",
                  {
                    channelName: `"${t(
                      "recruitmentPreference." + values.channel
                    )}"`,
                  }
                )}
                okLabel={t("ConductOutreachPage.yesLabel")}
                cancelLabel={t("ConductOutreachPage.noLabel")}
              />
              <ConfirmationDialog
                open={openASConfirmationForExistingRecurringSchedule}
                onClose={() =>
                  closeCancelASConfirmationForExistingRecurring(
                    resetForm,
                    values
                  )
                }
                message={t(
                  "ConductOutreachPage.errorMessageForExistingRecurring"
                )}
                okLabel={t("ConductOutreachPage.proceedLabel")}
                cancelLabel={t("ConductOutreachPage.cancelLabel")}
              />
              <ConfirmationDialog
                open={openASConfirmationForCancelRecurringWave}
                onClose={closeCancelASConfirmationForCancelRecurringWave}
                message={t(
                  "ConductOutreachPage.confirmationMessageCancelRecurringSchedule",
                  {
                    waveName:
                      cancelRecurringOutreachRow &&
                      cancelRecurringOutreachRow.waveName,
                  }
                )}
                okLabel={t("ConductOutreachPage.yesLabel")}
                cancelLabel={t("ConductOutreachPage.noLabel")}
              />
              <MuiGrid container className={classes.gridContainer}>
                <MuiGrid item style={{ width: "240px" }}>
                  <MuiTypography>
                    {t(`formLabel.irb`)}{" "}
                    <MuiTypography variant="subtitle1" display="inline">
                      {study.irbNumber}
                    </MuiTypography>
                  </MuiTypography>
                  <MuiTypography>
                    {t(`formLabel.protocolNumber`)}: &nbsp;
                    <MuiTypography variant="subtitle1" display="inline">
                      {study.protocolNumber}
                    </MuiTypography>
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item xs={2}>
                  <MuiTypography>
                    {`${t("formLabel.summaryAccrual")}:`}{" "}
                    <MuiTypography variant="subtitle1" display="inline">
                      {study && study.protocolSummaryAccrual
                        ? SummaryAccrual.SummaryAccrualTrue
                        : SummaryAccrual.SummaryAccrualFalse}
                    </MuiTypography>
                  </MuiTypography>
                  <MuiTypography>
                    {`${t("formLabel.studyNickname")}: `}{" "}
                    <MuiTypography variant="subtitle1" display="inline">
                      {study.nickname}
                    </MuiTypography>
                  </MuiTypography>
                </MuiGrid>
                <MuiGrid item xs={2}>
                  <MuiTypography>
                    {`${t("formLabel.demographicOptimization")}:`}{" "}
                    <MuiTypography variant="subtitle1" display="block">
                      {
                        DemographicOptimization[
                          study.demographicOptimizationStatus
                        ]
                      }
                    </MuiTypography>
                  </MuiTypography>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid
                container
                className={classes.gridContainer}
                justify="space-between"
              >
                <MuiGrid item xs={5} className={classes.gridSection}>
                  <MuiTypography className={classes.customMarginBottom}>
                    {t(`ConductOutreachPage.recommendationLabel`)}
                  </MuiTypography>
                  <MuiGrid container justify="space-between">
                    <MuiGrid item xs={6}>
                      <MuiTypography>
                        {`${t("studyTable.head_label_recruited")}: `}{" "}
                        <MuiTypography variant="subtitle1" display="inline">
                          {getRecruited(study)}
                        </MuiTypography>
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs={6}>
                      <MuiTypography>
                        {`${t("studyTable.head_label_time_elapsed")}: `}{" "}
                        <MuiTypography variant="subtitle1" display="inline">
                          {study.timeElapsed}
                        </MuiTypography>
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid container justify="space-between">
                    <MuiGrid item xs={6}>
                      <MuiTypography>
                        {`${t("formLabel.riskCount")}: `}{" "}
                        <MuiTypography variant="subtitle1" display="inline">
                          {study.riskCount}
                        </MuiTypography>
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs={6}>
                      <MuiTypography>
                        {`${t("studyTable.head_label_study_end_date")}: `}{" "}
                        <MuiTypography variant="subtitle1" display="inline">
                          {new Date(
                            study.recruitmentEndDate
                          ).toLocaleDateString()}
                        </MuiTypography>
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiTable className={classes.outreachTable} size="small">
                    <MuiTableHead>
                      <MuiTableRow>
                        <MuiTableCell />
                        <MuiTableCell>
                          <div className={classes.bestHeaderCell}>
                            <MuiStarIcon fontSize="small" />
                            <span>
                              {t(`ConductOutreachPage.recommendationBestLabel`)}
                            </span>
                          </div>
                        </MuiTableCell>
                        <MuiTableCell>
                          <div className={classes.worstHeaderCell}>
                            <MuiWarningIcon fontSize="small" />
                            <span>
                              {t(
                                `ConductOutreachPage.recommendationWorstLabel`
                              )}
                            </span>
                          </div>
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiTableHead>
                    <MuiTableBody>
                      <MuiTableRow>
                        <MuiTableCell>
                          {t(`ConductOutreachPage.recommendationPerformingDay`)}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.dayOfWeekBest)}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.dayOfWeekWorst)}
                        </MuiTableCell>
                      </MuiTableRow>
                      <MuiTableRow>
                        <MuiTableCell>
                          {t(
                            `ConductOutreachPage.recommendationPerformingHour`
                          )}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.hourBest)}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.hourWorst)}
                        </MuiTableCell>
                      </MuiTableRow>
                      <MuiTableRow>
                        <MuiTableCell>
                          {t(
                            `ConductOutreachPage.recommendationPerformingChannel`
                          )}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.channelBest)}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.channelWorst)}
                        </MuiTableCell>
                      </MuiTableRow>
                      <MuiTableRow>
                        <MuiTableCell>
                          {t(
                            `ConductOutreachPage.recommendationPerformingLotSize`
                          )}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.quantityBest)}
                        </MuiTableCell>
                        <MuiTableCell>
                          {getStatsDisplay(outreachStatistics.quantityWorst)}
                        </MuiTableCell>
                      </MuiTableRow>
                      <MuiTableRow>
                        <MuiTableCell>
                          {t(
                            `ConductOutreachPage.recommendationPerformingCohort`
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          className={classes.performingCohortTableCell}
                        >
                          {getCohortStatsDisplay(
                            outreachStatistics.importRequestBest
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          className={classes.performingCohortTableCell}
                        >
                          {getCohortStatsDisplay(
                            outreachStatistics.importRequestWorst
                          )}
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiTableBody>
                  </MuiTable>
                </MuiGrid>
                <MuiGrid item xs={6} className={classes.gridSection}>
                  <MuiTypography className={classes.customMarginBottom}>
                    {t(`ConductOutreachPage.outreachSettingsLabel`)}
                  </MuiTypography>
                  <MuiGrid item xs={12}>
                    <MuiTextField
                      name="waveName"
                      className={classes.formTextField}
                      label={t(`ConductOutreachPage.waveNameLabel`)}
                      variant="outlined"
                      value={values.waveName}
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!errors.waveName && !!touched.waveName}
                      helperText={
                        errors.waveName && !!touched.waveName
                          ? t("formValidation.requiredField")
                          : ""
                      }
                    />
                  </MuiGrid>
                  <MuiGrid
                    container
                    justify="space-between"
                    className={classes.gridContainer}
                  >
                    <MuiGrid item xs={8}>
                      <Autocomplete
                        id="source"
                        key="source"
                        size="small"
                        label={t(`ConductOutreachPage.cohortSourceLabel`)}
                        options={cohortSources || []}
                        getOptionLabel={(option) =>
                          option ? option.filename : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        getOptionSelected={(option, value) =>
                          option.id === value?.id
                        }
                        variant="outlined"
                        margin="dense"
                        value={values.source}
                        onBlur={handleBlur}
                        onChange={async (evt, newValue) => {
                          await setFieldValue("source", newValue || null);
                          setProspectCounts([]);

                          validateField("source");
                        }}
                        clientSide={true}
                        error={errors.source && touched.source}
                        helperText={
                          errors.source && touched.source
                            ? t("formValidation.requiredField")
                            : ""
                        }
                      />
                    </MuiGrid>
                    <MuiGrid container justify="flex-end" item xs={4}>
                      <div className={classes.wrapper}>
                        <MuiButton
                          variant="contained"
                          color="primary"
                          disabled={checkAvailabilityLoader}
                          onClick={() =>
                            handleClickCheckAvailability(values, setFieldValue)
                          }
                        >
                          {t(`ConductOutreachPage.availabilityButtonLabel`)}
                        </MuiButton>
                        {checkAvailabilityLoader && (
                          <MuiCircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiTableContainer className={classes.tableContainerRoot}>
                    <MuiTable size="small">
                      <MuiTableHead className={classes.tableHead}>
                        <MuiTableRow>
                          <MuiTableCell />
                          <MuiTableCell align="right">
                            {t(`ConductOutreachPage.emailLabel`)}
                          </MuiTableCell>
                          <MuiTableCell align="right">
                            {t(`ConductOutreachPage.mychartLabel`)}
                          </MuiTableCell>
                          <MuiTableCell align="right">
                            {t(`ConductOutreachPage.myhealthLabel`)}
                          </MuiTableCell>
                          <MuiTableCell align="right">
                            {t(`ConductOutreachPage.manualLabel`)}
                          </MuiTableCell>
                        </MuiTableRow>
                      </MuiTableHead>
                      <MuiTableBody>
                        <MuiTableRow>
                          <MuiTableCell className={classes.tableHead}>
                            {t(`ConductOutreachPage.availableLabel`)}
                          </MuiTableCell>
                          {StudyChannelOptions &&
                            Object.keys(StudyChannelOptions).map(
                              (channelValue, idx) => (
                                <MuiTableCell key={idx} align="right">
                                  <MuiTypography variant="subtitle1">
                                    {getProspectCount(
                                      prospectCounts,
                                      channelValue,
                                      "available"
                                    )}
                                  </MuiTypography>
                                </MuiTableCell>
                              )
                            )}
                        </MuiTableRow>
                        <MuiTableRow>
                          <MuiTableCell className={classes.tableHead}>
                            {t(`ConductOutreachPage.recommendedLabel`)}
                          </MuiTableCell>
                          {StudyChannelOptions &&
                            Object.keys(StudyChannelOptions).map(
                              (channelValue, idx) => (
                                <MuiTableCell key={idx} align="right">
                                  <MuiTypography variant="subtitle1">
                                    {getProspectCount(
                                      prospectCounts,
                                      channelValue,
                                      "recommended"
                                    )}
                                  </MuiTypography>
                                </MuiTableCell>
                              )
                            )}
                        </MuiTableRow>
                      </MuiTableBody>
                    </MuiTable>
                  </MuiTableContainer>
                  <MuiGrid
                    container
                    className={classes.customMarginBottom}
                    justify={"space-between"}
                    xs={12}
                  >
                    <MuiGrid item xs={3}>
                      <Autocomplete
                        id="channel"
                        key="channel"
                        size="small"
                        label={t(`ConductOutreachPage.channelLabel`)}
                        options={study.channelStatus || []}
                        getOptionDisabled={(option) =>
                          option.status ===
                          t(`ConductOutreachPage.channelStatus`)
                        }
                        getOptionLabel={(option) => {
                          return option.type
                            ? t("recruitmentPreference." + option.type)
                            : "";
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.type === value?.type
                        }
                        getOptionSelected={(option, value) =>
                          option.type === value?.type
                        }
                        variant="outlined"
                        margin="dense"
                        value={values.channel}
                        onBlur={handleBlur}
                        onChange={(evt, newValue) => {
                          const channelName = newValue || "";

                          // set templateName based on available study templates and Marketo permissions
                          channelName.type === "DE" &&
                            permissions.MARKETO_ENABLED &&
                            setTemplateNameValue(
                              study.studyTemplates,
                              setFieldTouched,
                              setFieldValue
                            );

                          handleChangeChannel(setFieldValue, channelName);
                          setFieldValue(
                            "firstOutreachDate",
                            getFirstOutreachDate(channelName.type ?? "")
                          );
                          setFieldValue(
                            "outreachTime",
                            getOutreachDateAndTime(
                              channelName.type ?? "",
                              !isSelectedChannelDisable
                            )
                          );
                        }}
                        clientSide={true}
                        error={errors.channel && touched.channel ? true : false}
                        helperText={
                          errors.channel && touched.channel
                            ? t("formValidation.requiredField")
                            : ""
                        }
                      />
                    </MuiGrid>
                    <MuiGrid item xs={2} className={classes.textFieldWidth}>
                      <MuiTextField
                        id="outreachQty"
                        key="outreachQty"
                        type="number"
                        label={t(`ConductOutreachPage.outreachQtyLabel`)}
                        variant="outlined"
                        value={values.outreachQty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="dense"
                        className={classes.selectMarginDense}
                        size="medium"
                        error={touched.outreachQty && errors.outreachQty}
                        helperText={
                          touched.outreachQty &&
                          errors.outreachQty &&
                          t("ConductOutreachPage.outreachQuantityErrorMessage")
                        }
                      />
                    </MuiGrid>
                    <MuiGrid item xs={4}>
                      <Autocomplete
                        id="outreachSchedule"
                        key="outreachSchedule"
                        size="small"
                        label={t(`ConductOutreachPage.outreachScheduleLabel`)}
                        options={Object.values(OutreachScheduleModes) || []}
                        getOptionLabel={(option) => t(option)}
                        getOptionDisabled={(option) =>
                          (isSelectedChannelDisable ||
                            (values.channel !== StudyChannelOptions.MC &&
                              values.channel !== StudyChannelOptions.MH)) &&
                          option === "ConductOutreachPage.scheduleRecurring"
                        }
                        variant="outlined"
                        margin="dense"
                        value={
                          values.outreachSchedule
                            ? valueForOutReach(values.outreachSchedule)
                            : null
                        }
                        onBlur={handleBlur}
                        onChange={async (e, newValue) => {
                          const selectedValue = Object.keys(
                            OutreachScheduleModes
                          ).find(
                            (key) => OutreachScheduleModes[key] === newValue
                          );
                          await setFieldValue(
                            "outreachSchedule",
                            selectedValue || null
                          );

                          validateField("outreachSchedule");
                        }}
                        clientSide={true}
                        error={Boolean(
                          errors.outreachSchedule && touched.outreachSchedule
                        )}
                        helperText={
                          errors.outreachSchedule && touched.outreachSchedule
                            ? t("formValidation.requiredField")
                            : ""
                        }
                      />
                    </MuiGrid>
                  </MuiGrid>
                  <MuiTextField
                    className={classes.formTextField}
                    name="waveNote"
                    label={t(`formLabel.note`)}
                    variant="outlined"
                    multiline={true}
                    size="small"
                    value={values.waveNote}
                    onChange={handleChange}
                  />
                  {values.channel.type === "DE" &&
                    permissions.MARKETO_ENABLED && (
                      <MuiGrid xs={4} className={classes.customMarginBottom}>
                        <Autocomplete
                          id="templateName"
                          name="templateName"
                          key="templateName"
                          size="small"
                          label={t(`ConductOutreachPage.emailTemplateLabel`)}
                          options={study.studyTemplates || []}
                          getOptionLabel={(option) => option.templateName}
                          getOptionSelected={(option, value) =>
                            option.templateName === value.templateName
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.templateName === value?.templateName
                          }
                          variant="outlined"
                          margin="dense"
                          value={values.templateName}
                          onBlur={handleBlur}
                          required
                          onChange={async (e, newValue) => {
                            await setFieldTouched("templateName", true);
                            setFieldValue("templateName", newValue || "");
                          }}
                          clientSide={true}
                          error={Boolean(
                            errors.templateName && touched.templateName
                          )}
                          helperText={
                            errors.templateName && touched.templateName
                              ? t("formValidation.requiredField")
                              : ""
                          }
                        />
                      </MuiGrid>
                    )}
                  {values.outreachSchedule === "ONE_TIME_ONLY" && (
                    <MuiGrid
                      container
                      className={classes.customMarginBottom}
                      justify={"space-between"}
                      xs={12}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {values.channel === StudyChannelOptions.MH &&
                        !isSelectedChannelDisable ? (
                          <MuiKeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            label={t(
                              "ConductOutreachPage.outreachDateLabelForMH"
                            )}
                            format="MM/dd/yyyy"
                            margin="dense"
                            disablePast
                            value={values.outreachTime}
                            onBlur={handleBlur}
                            onChange={(date, value) => {
                              setFieldValue("outreachTime", value);
                            }}
                            minDate={firstOutreachMinDate}
                            id="outreachTime"
                            name="outreachTime"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ autoComplete: "off" }}
                            minDateMessage={t(
                              "ConductOutreachPage.firstOutreachDateMinDateMessage"
                            )}
                            error={
                              !!errors.outreachTime && !!touched.outreachTime
                            }
                            helperText={
                              !!errors.outreachTime && !!touched.outreachTime
                                ? t("formValidation.requiredField")
                                : ""
                            }
                          />
                        ) : (
                          <MuiKeyboardDateTimePicker
                            className={classes.timePicker}
                            hideTabs
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label={t("ConductOutreachPage.outreachDateLabel")}
                            format="MM/dd/yyyy hh:mm a"
                            margin="dense"
                            disablePast
                            value={values.outreachTime}
                            onBlur={handleBlur}
                            onChange={(date, value) => {
                              setFieldValue("outreachTime", value);
                            }}
                            id="outreachTime"
                            name="outreachTime"
                            required={true}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ autoComplete: "off" }}
                            error={
                              !!errors.outreachTime && !!touched.outreachTime
                            }
                            helperText={
                              !!errors.outreachTime && !!touched.outreachTime
                                ? t("formValidation.requiredField")
                                : ""
                            }
                          />
                        )}
                      </MuiPickersUtilsProvider>
                    </MuiGrid>
                  )}
                  {values.outreachSchedule === "RECURRING" && (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiGrid
                          className={classes.customMarginBottom}
                          container
                          justify={"space-between"}
                        >
                          <MuiGrid item xs={4}>
                            <MuiKeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              required={true}
                              label={t(
                                "ConductOutreachPage.firstOutreachDateLabel"
                              )}
                              format="MM/dd/yyyy"
                              margin="dense"
                              disablePast
                              value={values.firstOutreachDate}
                              minDate={
                                values.channel === StudyChannelOptions.MH
                                  ? firstOutreachMinDate
                                  : new Date()
                              }
                              onBlur={handleBlur}
                              onChange={(date, value) => {
                                setFieldValue("firstOutreachDate", value);
                              }}
                              id="firstOutreachDate"
                              name="firstOutreachDate"
                              minDateMessage={t(
                                "ConductOutreachPage.firstOutreachDateMinDateMessage"
                              )}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ autoComplete: "off" }}
                              error={
                                !!errors.firstOutreachDate &&
                                !!touched.firstOutreachDate
                              }
                              helperText={
                                !!errors.firstOutreachDate &&
                                !!touched.firstOutreachDate
                                  ? t("formValidation.requiredField")
                                  : ""
                              }
                            />
                          </MuiGrid>
                          {values.channel !== StudyChannelOptions.MH && (
                            <MuiGrid item xs>
                              <MuiKeyboardTimePicker
                                margin={"dense"}
                                variant="inline"
                                inputVariant="outlined"
                                required={true}
                                mask="__:__ _M"
                                label={t(
                                  "ConductOutreachPage.outreachTimeOfDay"
                                )}
                                inputValue={values.outreachTimeOfDay}
                                value={values.outreachTimeOfDay}
                                onBlur={handleBlur}
                                onChange={(date, value) => {
                                  setRecurringOutreachDate(date);
                                  setFieldValue("outreachTimeOfDay", value);
                                }}
                                id="outreachTimeOfDay"
                                name="outreachTimeOfDay"
                                fullWidth
                                keyboardIcon={<MuiAccessTimeIcon />}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ autoComplete: "off" }}
                                error={
                                  !!errors.outreachTimeOfDay &&
                                  !!touched.outreachTimeOfDay
                                }
                                helperText={
                                  !!errors.outreachTimeOfDay &&
                                  !!touched.outreachTimeOfDay
                                    ? t("formValidation.requiredField")
                                    : ""
                                }
                              />
                            </MuiGrid>
                          )}
                          <MuiGrid item xs={4}>
                            <MuiKeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              required={true}
                              label={t(
                                "ConductOutreachPage.lastOutreachDateLabel"
                              )}
                              format="MM/dd/yyyy"
                              margin="dense"
                              disablePast
                              value={values.lastOutreachDate}
                              onBlur={handleBlur}
                              onChange={(date, value) => {
                                setFieldValue("lastOutreachDate", value);
                              }}
                              id="lastOutreachDate"
                              name="lastOutreachDate"
                              maxDate={addYears(
                                new Date(values.firstOutreachDate),
                                1
                              )}
                              minDate={new Date(values.firstOutreachDate)}
                              minDateMessage={t(
                                "ConductOutreachPage.lastOutreachDateMinDateMessage"
                              )}
                              maxDateMessage={t(
                                "ConductOutreachPage.lastOutreachDateMaxDateMessage"
                              )}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ autoComplete: "off" }}
                              error={
                                !!errors.firstOutreachDate &&
                                !!touched.firstOutreachDate
                              }
                              helperText={
                                !!errors.firstOutreachDate &&
                                !!touched.firstOutreachDate
                                  ? t("formValidation.requiredField")
                                  : ""
                              }
                            />
                          </MuiGrid>
                        </MuiGrid>
                      </MuiPickersUtilsProvider>
                      <MuiFormControl
                        className={classes.customMarginBottom}
                        component="fieldset"
                      >
                        <MuiTypography color="textSecondary">
                          {t("ConductOutreachPage.scheduleRecurringLabel")}
                        </MuiTypography>
                        <MuiFormGroup row={true}>
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[0]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.sundayLabel")}
                          />
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[1]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.mondayLabel")}
                          />
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[2]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.tuesdayLabel")}
                          />
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[3]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.wednesdayLabel")}
                          />
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[4]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.thursdayLabel")}
                          />
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[5]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.fridayLabel")}
                          />
                          <MuiFormControlLabel
                            control={
                              <MuiCheckbox
                                name={"scheduleRecurringCheckbox"}
                                color="primary"
                                value={DaysOfTheWeek[6]}
                                onChange={handleChange}
                              />
                            }
                            label={t("ConductOutreachPage.saturdayLabel")}
                          />
                        </MuiFormGroup>
                      </MuiFormControl>
                    </>
                  )}
                  <MuiGrid
                    container
                    item
                    className={classes.formButton}
                    justify="flex-end"
                  >
                    <MuiGrid item xs={2}>
                      <MuiButton
                        color="primary"
                        onClick={() => handleClickReset(resetForm)}
                      >
                        {t(`formLabel.buttonReset`)}
                      </MuiButton>
                    </MuiGrid>
                    <MuiGrid container justify="flex-end" item xs={2}>
                      <div className={classes.wrapper}>
                        <MuiButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isSubmitting ||
                            prospectCounts.length === 0 ||
                            conductOutreachLoader
                          }
                        >
                          {t(`formLabel.submitButtonLabel`)}
                        </MuiButton>
                        {conductOutreachLoader && (
                          <MuiCircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </Form>
          );
        }}
      </Formik>
      <MuiGrid item xs={12} className={classes.gridContainer}>
        <MuiTypography variant="h6">
          {t(`ConductOutreachPage.recurringOutreachLabel`)}
        </MuiTypography>
      </MuiGrid>
      <MuiTableContainer
        className={classes.customMarginBottom}
        component={MuiPaper}
      >
        <MuiTable stickyHeader size="small">
          <MuiTableHead>
            <MuiTableRow>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnStartDate`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnStatus`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnWaveName`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.tableColumnCohortSource`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnCohortChannel`)}
              </MuiTableCell>
              <MuiTableCell align="right">
                {t(`ConductOutreachPage.recurringTableColumnQuantity`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnDays`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnTime`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.recurringTableColumnEndDate`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.tableColumnNote`)}
              </MuiTableCell>
              <MuiTableCell>
                {t(`ConductOutreachPage.tableColumnActions`)}
              </MuiTableCell>
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {recurringOutreach && recurringOutreach.length ? (
              recurringOutreach.map((recurringRow) => (
                <MuiTableRow>
                  <MuiTableCell>
                    {convertDateStringToLocalDatetime(
                      recurringRow.scheduleSummary.startDate
                    )
                      ? convertDateStringToLocalDatetime(
                          recurringRow.scheduleSummary.startDate
                        ).toLocaleDateString()
                      : null}
                  </MuiTableCell>
                  <MuiTableCell>
                    {recurringRow.scheduleSummary.status}
                  </MuiTableCell>
                  <MuiTableCell width="170">
                    {recurringRow.waveName}
                  </MuiTableCell>
                  <MuiTableCell width="270">
                    {recurringRow.importRequest.filename}
                  </MuiTableCell>
                  <MuiTableCell>{recurringRow.channel}</MuiTableCell>
                  <MuiTableCell align="right">
                    {recurringRow.quantity}
                  </MuiTableCell>
                  <MuiTableCell width="270">
                    {sortedArrayOfDaysOfWeek(
                      recurringRow.scheduleSummary.recurringDays
                    )}
                  </MuiTableCell>
                  <MuiTableCell>
                    {timeConvert(recurringRow.scheduleSummary.recurringTime)}
                  </MuiTableCell>
                  <MuiTableCell>
                    {convertDateStringToLocalDatetime(
                      recurringRow.scheduleSummary.endDate
                    )
                      ? convertDateStringToLocalDatetime(
                          recurringRow.scheduleSummary.endDate
                        ).toLocaleDateString()
                      : null}
                  </MuiTableCell>
                  <MuiTableCell
                    className={recurringRow.note ? classes.noteTableCell : ""}
                  >
                    {recurringRow.note}
                  </MuiTableCell>
                  <MuiTableCell>
                    <MuiTooltip
                      title={t(
                        "ConductOutreachPage.rowTooltipRecurringCancelIcon"
                      )}
                      arrow
                    >
                      <MuiIconButton
                        size="small"
                        onClick={() => {
                          handleClickCancelRecurring();
                          setCancelRecurringOutreachRow(recurringRow);
                        }}
                      >
                        <MuiCancelIcon />
                      </MuiIconButton>
                    </MuiTooltip>
                  </MuiTableCell>
                </MuiTableRow>
              ))
            ) : (
              <MuiTableRow>
                <MuiTableCell colSpan={11} align="center">
                  {t(`LookupProspect.emptyTableRow`)}
                </MuiTableCell>
              </MuiTableRow>
            )}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
      <MuiGrid container item xs={12} className={classes.customMarginBottom}>
        <MuiTypography variant="h6">
          {t(`ConductOutreachPage.historyLabel`)}
        </MuiTypography>
        {study.isHavingErrorSummary && (
          <MuiGrid container justify="flex-end" item xs>
            <MuiButton
              variant="outlined"
              onClick={() => setOpenOutreachFailureSummaryDialog(true)}
            >
              <MuiErrorOutlineIcon fontSize="small" color="error" />
              &nbsp;
              {t(`ConductOutreachPage.outreachAutomatedErrorLabel`)}
            </MuiButton>
          </MuiGrid>
        )}
      </MuiGrid>
      <MuiPaper className={classes.customMarginBottom}>
        <MuiTableContainer
          className={classes.overflowTableContainer}
          component={MuiPaper}
        >
          <MuiTable stickyHeader size="small">
            <MuiTableHead className={classes.tableHead}>
              {outreachHistoryHeadCells.map((headCell) => (
                <MuiTableCell
                  key={headCell.id}
                  align={headCell.align ? headCell.align : ""}
                >
                  {headCell.sort ? (
                    <MuiTableSortLabel
                      active={sort.orderBy === headCell.id}
                      direction={
                        sort.orderBy === headCell.id ? sort.order : "asc"
                      }
                      onClick={createSortHandler(headCell.id)}
                    >
                      {t(`ConductOutreachPage.${headCell.label}`)}
                    </MuiTableSortLabel>
                  ) : (
                    t(`ConductOutreachPage.${headCell.label}`)
                  )}
                </MuiTableCell>
              ))}
            </MuiTableHead>
            <MuiTableBody>
              <MuiTableRow key={-1}>
                <MuiTableCell />
                <MuiTableCell />
                <MuiTableCell />
                <MuiTableCell />
                <MuiTableCell />
                <MuiTableCell />
                <MuiTableCell />
                <MuiTableCell>
                  {t(`ConductOutreachPage.tableTotalRowTotals`)}
                </MuiTableCell>
                <MuiTableCell />
                <MuiTableCell align="right">
                  <div className={classes.cellWithInfoContainer}>
                    {study && study.outreachSummary.adjustedOutreachQuantity}
                    <MuiInfoIcon
                      color="primary"
                      onClick={() => displayOutreachQuantityDialog()}
                    />
                    {openDialog && (
                      <OutreachDetails
                        onClose={closeOutreachQuantityDialog}
                        open={openDialog}
                        outreach={{
                          id: study.id,
                          quantity:
                            study.outreachSummary.adjustedOutreachQuantity,
                          requestedQuantity: study.outreachSummary.total,
                          outreachSummary: study.outreachSummary,
                          responseSummary: study.responseSummary,
                          subjectSummary: study.subjectSummary,
                        }}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        totalInfo={true}
                      />
                    )}
                  </div>
                </MuiTableCell>
                <MuiTableCell align="right">
                  {study && study.responseSummary.responded}
                </MuiTableCell>
                <MuiTableCell
                  align="right"
                  className={classes.interestedTableCell}
                >
                  {study && study.responseSummary.interested}
                </MuiTableCell>
                <MuiTableCell align="right">
                  {study && study.responseSummary.notInterested}
                </MuiTableCell>
                <MuiTableCell
                  align="right"
                  className={classes.unsubscribedTableCell}
                >
                  {study && study.responseSummary.unsubscribed}
                </MuiTableCell>
                <MuiTableCell align="right">
                  {study && study.subjectSummary.pepEnrolled}
                </MuiTableCell>
                <MuiTableCell />
                <MuiTableCell />
              </MuiTableRow>

              {outreachHistory &&
                addIndexToElements(outreachHistory, rowsPerPage, page).map(
                  (row) => (
                    <MuiTableRow key={row.id}>
                      <MuiTableCell align="right">{row.index}</MuiTableCell>
                      <MuiTableCell>
                        {row.outreachMode === OutreachChannelModes.STANDARD ? (
                          <MuiTooltip
                            title={t(
                              "ConductOutreachPage.tootltipStandardMode"
                            )}
                            arrow
                          >
                            <MuiAvatar className={classes.orange}>
                              <MuiTypography variant="caption">
                                {row.outreachMode.charAt(0)}
                              </MuiTypography>
                            </MuiAvatar>
                          </MuiTooltip>
                        ) : (
                          <MuiTooltip
                            title={t(
                              "ConductOutreachPage.tootltipAutomatedMode"
                            )}
                            arrow
                          >
                            <MuiAvatar className={classes.blue}>
                              <MuiTypography variant="caption">
                                {row.outreachMode.charAt(0)}
                              </MuiTypography>
                            </MuiAvatar>
                          </MuiTooltip>
                        )}
                      </MuiTableCell>
                      <MuiTableCell>
                        <MuiGrid container item>
                          {row.initiationMode ===
                          OutreachInitiationModes.SYSTEM ? (
                            <MuiTooltip
                              title={t(
                                "ConductOutreachPage.tootltipRecurringSchedule"
                              )}
                              arrow
                            >
                              <MuiAutorenewIcon
                                className={classes.recurringIcon}
                                color="primary"
                              />
                            </MuiTooltip>
                          ) : null}
                        </MuiGrid>
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.status &&
                        row.status !== "FAILED" &&
                        row.status !== "SCHEDULED" &&
                        row.status !== "CANCELED" ? (
                          <PEExport
                            displayName={row.waveName}
                            // TODO: use wavename_<filename>
                            downloadName={row.waveName + ".csv"}
                            channel={row.channel}
                            exportURL={`${URLs.GET_OUTREACH_URL_BASE}/${row.id}/file`}
                          />
                        ) : (
                          row.waveName
                        )}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.status &&
                        (row.status === OutreachStatuses.FAILED ||
                          row.status === OutreachStatuses.FINISHED_WITH_ERROR ||
                          row.status ===
                            OutreachStatuses.GENERATED_WITH_ERROR) ? (
                          <div className={classes.cellWithInfoContainer}>
                            {row.status}
                            <MuiWarningIcon
                              className={classes.warning}
                              onClick={() => {
                                displayFailureSummaryDialog(row.index);
                                handleOutreachErrorContent(row);
                              }}
                            />
                            {failureSummaryDialog &&
                              row.index === failedOutreach && (
                                <PEErrorDialog
                                  onClose={closeFailureSummaryDialog}
                                  open={failureSummaryDialog}
                                  errorsList={errorsList}
                                  title={t(`OutreachFailureSummary.title`)}
                                  primaryContent={
                                    errorSummaryContent.primaryContent
                                  }
                                  secondaryContent={
                                    errorSummaryContent.secondaryContent
                                  }
                                  displayDynamicPrimarySubHeading={true}
                                />
                              )}
                          </div>
                        ) : (
                          <div className={classes.cellWithInfoContainer}>
                            {row.status ||
                              t(`ConductOutreachPage.tableColumnNA`)}
                          </div>
                        )}
                      </MuiTableCell>

                      <MuiTableCell>{row.channel}</MuiTableCell>
                      <MuiTableCell className={classes.cohortSourceTableCell}>
                        {row.importRequest.filename}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.conductedDate
                          ? new Date(row.conductedDate).toLocaleString()
                          : null}
                      </MuiTableCell>
                      <MuiTableCell>{row.conductedDay}</MuiTableCell>
                      <MuiTableCell align="right">
                        <div className={classes.cellWithInfoContainer}>
                          {row.quantity}

                          <MuiInfoIcon
                            color="primary"
                            onClick={() =>
                              displayOutreachQuantityDialog(row.index)
                            }
                          />
                          {openDialog && row.index === selectedOutreach && (
                            <OutreachDetails
                              onClose={closeOutreachQuantityDialog}
                              open={openDialog}
                              outreach={{
                                id: row.id,
                                status: row.status,
                                quantity: row.quantity,
                                requestedQuantity: row.requestedQuantity,
                                outreachSummary: row.outreachSummary,
                                responseSummary: row.responseSummary,
                                subjectSummary: row.subjectSummary,
                                channel: row.channel,
                                subchannel: row.subchannel,
                                waveName: row.waveName,
                                studyId: study.id,
                              }}
                              refresh={refresh}
                              setRefresh={setRefresh}
                              summaryAccrual={study.protocolSummaryAccrual}
                            />
                          )}
                        </div>
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        {row.responseSummary.responded}
                      </MuiTableCell>
                      <MuiTableCell
                        align="right"
                        className={classes.interestedTableCell}
                      >
                        {row.responseSummary.interested}
                      </MuiTableCell>
                      <MuiTableCell align="right">
                        {row.responseSummary.notInterested}
                      </MuiTableCell>
                      <MuiTableCell
                        align="right"
                        className={classes.unsubscribedTableCell}
                      >
                        {row.channel !== StudyChannelOptions.MC
                          ? row.responseSummary.unsubscribed
                          : "N/A"}
                      </MuiTableCell>
                      {/* TODO: update with correct count */}
                      <MuiTableCell align="right">
                        {row.subjectSummary.pepEnrolled}
                      </MuiTableCell>
                      <MuiTableCell className={classes.noteTableCell}>
                        {row.note}
                      </MuiTableCell>
                      <MuiTableCell>
                        {row.status === OutreachStatuses.SCHEDULED ? (
                          <MuiTooltip
                            title={t(
                              "ConductOutreachPage.rowTooltipCancelIcon"
                            )}
                            arrow
                          >
                            <MuiIconButton
                              size="small"
                              onClick={() => {
                                handleClickCancel();
                                setCancelOutreachRow(row);
                              }}
                            >
                              <MuiCancelIcon />
                            </MuiIconButton>
                          </MuiTooltip>
                        ) : (
                          <MuiIconButton size="small">
                            <MuiCancelIcon
                              className={classes.disabledIconState}
                            />
                          </MuiIconButton>
                        )}
                      </MuiTableCell>
                    </MuiTableRow>
                  )
                )}
            </MuiTableBody>
          </MuiTable>
        </MuiTableContainer>
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={outreachHistoryTotalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </MuiPaper>
    </div>
  ) : null;
};

export default ConductOutreachPageContent;
